import { $post, $get } from "@/request/index";

export function test() {
    return $post("/test/list");
}

// 获得线索分析研判链接
export function getCluesAnalysisPath() {
    return $post("/clues/analysis");
}

//获取用户信息
export function getUserInfo(data) {
    return $get("/login/info",data);
}

//获取用户信息
export function setStyle(data) {
    return $get("/style",data);
}

// 第一次访问此功能 
export function firstVisit(data){
    return $post("/menu/visit", data)
}

// 内网日志上报
export function sendLog(data) {
    return $post("/logstores/tianji_web_fe/track", data);
}