/**
 * 导出规范：
 *      router/model的模块引入，应该都导出一下三个字段
 *         - Components 模块import
 *         - staticRoutes  要添加的静态路由
 *         - staticRoutes[]['parent']  此路由要插入的父路由，默认在app.router下
 */
import {projectTypeList } from "@/config/index.config.js";

// 待引入模块
const Components = {
    // 403页面
    'page403':() => import(/* webpackChunkName: 'page403' */ '@/pages/util_view/403')
};

const loginComponent = () => import(/* webpackChunkName: 'Login' */ '@/pages/login/index')
//  不要权限控制的静态路由, 写在此处可访问
const staticRoutes = [
    ...projectTypeList.map(p=>({
        path:p.loginPath,
        component:p.component||loginComponent,
    })),
    {
        path: '/jump-pages',
        name: 'jump-pages',
        parent: '',
        component: () => import(/* webpackChunkName: 'jump-pages' */ '@/pages/util_view/jump_pages'),
    },
    {
        path: '/jz_sso_login',
        parent: '',
        component: () => import(/* webpackChunkName: 'jz_sso_login' */ '@/pages/util_view/jz_sso'),
    },
    {
        path: '/404',
        name: '404-page',
        parent: '',
        component: () => import(/* webpackChunkName: '404' */ '@/pages/util_view/404'),
    },
]
// v3打包的时候增加index页面
// if (process.env.VUE_APP_BUILD_VERSION === 'v3') {
//     staticRoutes.push({ path: '/index', name: 'index', component: () => import(/* webpackChunkName: 'index' */ '@/pages/index/index') })
// }



// 导出
export {
    Components,
    staticRoutes
}