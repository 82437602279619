import Cookies from "js-cookie";
import SlsWebLogger from 'js-sls-logger'

import {sendLog} from '@/api/index'

// 构建上报
const loggerOpts = {
    host: 'cn-qingdao.log.aliyuncs.com',      
    project: 'safeguard-qd',                 
    logstore: 'tianji_web_fe',               
    time: 10, 
    count: 10, 
    APIVersion: "0.6.0"
}
const logger = new SlsWebLogger(loggerOpts)

// 错误监控上报
const IsNeedUpload = process.env.VUE_APP_ENV === "PUBLIC"
export const ErrorUpload = function (type, errorData = {}, addInfo= {}) {
    let objMsg = {
        type,
        ak: '0E723879290B6E7566221AA52C277CD1',
        appVersion: `${process.env.VUE_APP_BUILD_VERSION}:${document.getElementById('app-version')?.getAttribute('version') || '-'}`,
        url: location.href,
        ua: navigator.userAgent,
        env: process.env.VUE_APP_ENV,
        uid: Cookies.getJSON(window.$tj_config["cookies"]["USERINFO"])?.login_name || '',
    }

    let string = errorData.msg.toLowerCase();
    let substring = "script error";
    if (type === 'js_error' && string.indexOf(substring) > -1){
        objMsg = {
            ...objMsg,
            'msg': '这个错误的所在JS跨域了，只能通过控制台查看',
            ...addInfo,
        }
    }
    
    else if (type === 'js_error') {
        objMsg = {
            ...objMsg,
            'msg': errorData.msg,
            'lineColumn':  errorData.lineNo ? `${errorData.lineNo}:${errorData.columnNo}` : '',
            ...addInfo
        }
    }

    else if (type === 'http_error') {
        objMsg = {
            ...objMsg,
            'msg': errorData.msg,
            'requestUrl': errorData.requestUrl,
            "method": errorData.method,
            "requestData": errorData.requestData,  // 字符串json的方式
            "httpStatus": errorData.httpStatus,
            "codeStatus": errorData.codeStatus,
            ...addInfo,
        }
    }
    else {
        objMsg = {
            ...objMsg,
            ...errorData
        }
    }

    // 上报到阿里云
    if(IsNeedUpload){
        logger.send(objMsg)
    }else{
        sendLog(objMsg)
    }

    return objMsg;
}


// 监控全局的请求状态
window.onerror = function(msg, errorFile, lineNo, columnNo, errorObj) {
    ErrorUpload(
        'js_error',
        { msg, errorFile, lineNo, columnNo, errorObj }
    )
}


// 上报HTTP错误给阿里云
export const HttpErrorUpload = function(response, apiExp, addInfo = {}) {
    ErrorUpload('http_error', {
        'msg': `接口请求异常 - Http状态码：${response.status}，code码：${response?.data?.codeStatus || 'unkonw'}`,
        'requestUrl': apiExp.config.url,
        "method": apiExp.config.method,
        "requestData": response.config.data,  // 字符串json的方式
        "httpStatus": response.status,
        "codeStatus": response?.data?.code || response?.data?.status || 'unkonw',
    }, addInfo)
}